<template>
    <div>
      <translate-report-list></translate-report-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>